<template>
  <div
    class="container-sm"
    :style="{ '--activeColor': rootColor || '#0A64A1' }"
  >
    <div class="form-box">
      <el-form label-position="top" label-width="80px" :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="反馈标题" prop="title">
          <el-input v-model="form.title" placeholder="请输入反馈标题"></el-input>
        </el-form-item>
        <el-form-item label="反馈内容" prop="message">
          <el-input v-model="form.message" type="textarea" :autosize="{ minRows: 6, maxRows: 8}" placeholder="请输入反馈内容"></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="用户姓名" prop="userName">
              <el-input v-model="form.userName" placeholder="请输入用户姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号码" prop="userMobile">
              <el-input v-model="form.userMobile" placeholder="请输入手机号码"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label=" 联系电话" prop="userPhone">
              <el-input v-model="form.userPhone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <div class="btn-box">
            <el-button plain @click="reset">重置</el-button>
            <el-button type="primary" @click="bindSubmit" plain>提交</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import settings from '@/config.js'
export default {
  name: "FeedBack",
  props: {
    rootColor: String,
  },
  data() {
    var mobileCheck = (rule, value, callback) => {
      if(/^\d{11}$/.test(value)) callback()
      else callback(new Error('请输入11位手机号码'))
    }
    return {
      form: {
        createdBy: '官网',
        //反馈标题
        title: '',
        //反馈内容
        message: '',
        //用户名称
        userName: '',
        // 用户手机号码
        userMobile: '',
        //电话
        userPhone: '',
      },
      rules: {
        title: [{required: true, message: '请填写反馈标题', trigger: 'blur'}],
        message: [{required: true, message: '请填写反馈内容', trigger: 'blur'}],
        userName: [{required: true, message: '请填写用户姓名', trigger: 'blur'}],
        userMobile: [
          {required: true, message: '请填写手机号码', trigger: 'blur'},
          {validator: mobileCheck, trigger: 'blur'}
        ]
      }
    };
  },
  watch: {
  },
  created() {
    
  },
  methods: {
    bindSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if(valid) {
          this.$http.post(`/leave/message/add`, this.form, {
            baseURL: settings.WT_URL,
            headers: {'ContentType': 'application/json'},
          }).then(res => {
            this.$alert('感谢您的反馈！', '成功提示', {type: 'success', showConfirmButton: false, closeOnClickModal: true})
            // this.$message({
            //   type: 'success',
            //   message: '反馈成功'
            // })
            this.reset()
          })
        }
      })
    },
    reset() {
      this.$refs.ruleForm.resetFields()
    },
  },
};
</script>


<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.form-box {
  padding: 0 20px;
  text-align: left;
  .el-form ::v-deep .el-form-item__label {
    color: #333;
    font-weight: bolder;
    padding: 0;
  }
  .el-input ::v-deep .el-input-group__append {
    background-color: #eef5fe;
  }
  .btn-box {
    display:flex;
    justify-content:center;
    margin-top:30px;
  }
  animation: form-inter .6s ease-out;
}
@keyframes form-inter {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
